
import React from 'react';
import SocialFollow from './SocialFollow';


import './Footer.css';

function Footer() {
    return (
        <div className='footer'>
            <SocialFollow />
            <span className='tidal-paints'>Tidal Paints ™, 2021</span>
        </div>
    )
}

export default Footer
