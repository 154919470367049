import React from 'react';
import "./NavBar.css";
import { Link } from "react-router-dom";
import Image from "../resources/logo.jpg";

function NavBar() {
    return (
        <nav className="navbar">
            <Link to="/">
                <img className="logo" alt="tidal paints logo"
                    src={Image}/>
            </Link>
            <div className="header-links">
                <Link to="/mission">
                    <span className="header-text" id='link-1'>Our Mission</span>
                </Link>
                <Link to="/contact">
                    <span className="header-text" id='link-2'>Contact Us</span>
                </Link>
            </div>
        </nav>
    )
}

export default NavBar;
